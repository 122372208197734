import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Warmup: Snatch Grip Split Jerk off back 5×4`}</p>
    <p>{`OHS 4×8\\@75% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`21-15-9 reps each of:`}</p>
    <p>{`Box Jumps (24/20″)`}</p>
    <p>{`Power Snatch (95/65)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`There is an American Heart Association walk this Saturday which
starts at 9:00am and goes down Main street so you may want to arrive
early for class and park either on Washington or Market Streets.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      